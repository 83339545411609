const portfoilo_shared_data = {
    "basic_info": {
        "name": "Steven Smith",
        "titles": [ "Full Stack Developer", "Senior Manager", "Aspiring Pilot" ],
        "social": [
            {
                "name": "Github Profile",
                "url": "https://github.com/ssmith353",
                "class": "fab fa-github"
            },
            {
                "name": "LinkedIn",
                "url": "https://www.linkedin.com/in/ssmith353/",
                "class": "fab fa-linkedin"
            },
            {
                "name": "Plane Tracker",
                "url": "https://globe.adsbexchange.com/?feed=T7FUh80zIM0z",
                "class": "fa fa-plane"
            }
        ],
        "image": "myProfile.jpg"
    },
    "skills": {
        "icons": [
            {
                "name": "React",
                "class": "devicon-react-plain",
                "level": "70"
            },
            {
                "name": "Java",
                "class": "devicon-java-plain",
                "level": "95"
            },
            {
                "name": "Webpack",
                "class": "devicon-webpack-plain",
                "level": "95"
            },
            {
                "name": "Git",
                "class": "devicon-git-plain",
                "level": "70"
            },
            {
                "name": "JavaScript",
                "class": "devicon-javascript-plain",
                "level": "70"
            },
            {
                "name": "MySql",
                "class": "devicon-mysql-plain",
                "level": "60"
            },
            {
                "name": "Kafka",
                "class": "devicon-apachekafka-plain",
                "level": "60"
            }
        ]
    }
}

const resume_data = {
    "basic_info": {
        "description_header": "Hi 👋 ",
        "description": "I'm Steven. I'm an experienced technical engineering manager at Compassion International. I enjoy playing a technical advisory role for my team, and love making the best possible product on the shortest timeline.",
        "section_name": {
            "about": "About me",
            "projects": "Projects",
            "skills": "Skills",
            "experience": "Experience"
        }
    },
    "projects": [
        {
            "title": "Liferay Automated Demo",
            "startDate": "2018",
            "description": "Create an demo site specifically created for each user, on demand.",
            "images": [
                "images/projects/liferay.webp"
            ],
            "url": "https://www.liferay.com/request-a-demo",
            "technologies": [
                {
                    "class": "devicon-react-plain",
                    "name": "React"
                },
                {
                    "class": "devicon-docker-plain",
                    "name": "Docker"
                },
                {
                    "class": "devicon-amazonwebservices-original",
                    "name": "AWS"
                }
            ]
        },
        {
            "title": "Compassion Moments",
            "startDate": "2020",
            "description": "Create a fast way for program countries to upload candid photos of children via a PWA. Leveraging React best practices for offline use, low connectivity, and high resiliency. ",
            "images": [
                "images/projects/cmoments.webp"
            ],
            "url": "https://compassionmoments.org/",
            "technologies": [
                {
                    "class": "devicon-react-original",
                    "name": "React"
                },
                {
                    "class": "devicon-javascript-plain",
                    "name": "JavaScript"
                },
                {
                    "class": "devicon-amazonwebservices-original",
                    "name": "AWS"
                }
            ]
        },
        {
            "title": "Compassion Messenger",
            "startDate": "2022",
            "description": "Leveraging Twilio and WhatsApp to allow cross channel messaging between supported children and their sponsors. Automated message filtering and flagging via SightEngine and Rokognition to allow near real time messaging. ",
            "images": [
                "images/projects/twilio.webp"
            ],
            "url": "https://www.twilio.com/whatsapp",
            "technologies": [
                {
                    "class": "devicon-react-original",
                    "name": "React"
                },
                {
                    "class": "devicon-javascript-plain",
                    "name": "JavaScript"
                },
                {
                    "class": "devicon-amazonwebservices-original",
                    "name": "AWS"
                }
            ]
        }
    ],
    "experience": [
        {
            "company": "@Compassion International",
            "title": "Senior Manager of Innovation Engineering",
            "years": "05/2021 - present",
            "mainTech": [
            ],
            "technologies": [
                "Rapid Prototyping",
                "Google Design Sprints"
            ]
        },
        {
            "company": "@Compassion International",
            "title": "Software Developer III",
            "years": "01/2020 - 05/2021",
            "mainTech": [
                "React"
            ],
            "technologies": [
                "React",
                "DyanamoDB",
                "RDS",
                "Event Based Architecture",
                "Serverless"
            ]
        },
        {
            "company": "Happy Money Inc",
            "title": "Software Developer",
            "years": "08/2019 - 01/2020",
            "mainTech": [
                "React Native"
            ],
            "technologies": [
                "React Native",
                "iOS/Android Dev",
                "Azure",
                "Startup"
            ]
        },
        {
            "company": "Liferay Inc",
            "title": "Software Developer",
            "years": "06/2014 - 08/2019",
            "mainTech": [
                "React"
            ],
            "technologies": [
                "Liferay DXP",
                "React",
                "MetalJS"
            ]
        }
    ]
}

export { portfoilo_shared_data, resume_data }
