import React from "react";
import "./App.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./components/About";
import Experience from "./components/Experience";
import Projects from "./components/Projects";
import Skills from "./components/Skills";

import { portfoilo_shared_data, resume_data } from './utils/dataStore'

const App = () => {
    const sharedData = portfoilo_shared_data
    const resumeData = resume_data

    return (
        <div>
            <Header sharedData={sharedData.basic_info}/>

            <About
                resumeBasicInfo={resumeData.basic_info}
                sharedBasicInfo={sharedData.basic_info}
            />
            <Projects
                resumeProjects={resumeData.projects}
                resumeBasicInfo={resumeData.basic_info}
            />
            <Skills
                sharedSkills={sharedData.skills}
                resumeBasicInfo={resumeData.basic_info}
            />
            <Experience
                resumeExperience={resumeData.experience}
                resumeBasicInfo={resumeData.basic_info}
            />
            <Footer sharedBasicInfo={sharedData.basic_info}/>
        </div>
    );
}

export default App;
